import APIReq from "../_APIReq";
import { API_URL, API_COMMON_ERROR_MSG } from "../../plugins/Const";

const api = {
    request: async(picData, xrayType, imgName) => {
        let retData = {
            isSuccess: false,
            errorMsg: '',
            fileObj: null,
            status: 200,
        };
        try {
            const form = new FormData();
            form.append('xrayType',xrayType);
            if(imgName){
                form.append('picData',picData,imgName);
            }else{
                form.append('picData',picData);
            }

            const config = {
                headers: {
                  'Content-Type': 'application/dicom' // 헤더 설정
                }
              };

            const res = await APIReq(`${API_URL}/file/uploadDcm`, form, 'post',config);
            if (res && res.data && res.data.result) {   
                retData.isSuccess = true;
                retData.fileObj = res.data.fileObj;
                retData.patientObj = res.data.patientObj;
                retData.resultObj = res.data.resultObj;
                retData.isPatientValid = res.data.isPatientValid;
            } else if (res && res.data && res.data.error) {
                retData.isSuccess = false;
                retData.errorMsg = res.data.error;
            } else {
                retData.isSuccess = false;
                retData.errorMsg = API_COMMON_ERROR_MSG;
            }
        } catch (e) {
            retData.isSuccess = false;
            retData.status = e.response.status;
            retData.errorMsg = e.response.data.message ?? API_COMMON_ERROR_MSG;
        }
        return retData;
    }
};
export default api